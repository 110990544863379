// React
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty } from "@mefisto/utils";
// Framework
import {
  makeStyles,
  Grid,
  Spinner,
  EmptyPlaceholder,
  ErrorPlaceholder,
} from "ui/components";
// Components
import FeedSkeletons from "../FeedSkeletons";
import FeedInfiniteList from "../FeedInfiniteList";
import FeedIncrementalList from "../FeedIncrementalList";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  root: ({ display, loading }) => ({
    height: "100%",
    position: display === "infinite" || loading ? "relative" : "inherit",
  }),
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Feed = ({
  display = "infinite",
  items,
  spacing,
  error,
  loading,
  loadingMore,
  hasMore,
  skeleton,
  skeletonCount = 20,
  contentProps,
  emptyPlaceholderProps,
  errorPlaceholderProps,
  onLoadMore,
  onRefresh,
  // @deprecated
  emptyDisabled,
  emptyTitle,
  emptySubtitle,
}) => {
  // Memo
  const empty = useMemo(() => {
    return isEmpty(items);
  }, [items]);
  const displayValue = useMemo(() => {
    return display === "default" ? "infinite" : display;
  }, [display]);
  // Styles
  const classes = useStyles({
    display: displayValue,
    loading: loading || error || empty,
  });
  // Handlers
  const handleLoadMore = useCallback(() => {
    if (!loadingMore) {
      onLoadMore();
    }
  }, [onLoadMore, loadingMore]);
  // Render
  return (
    <div className={classes.root}>
      {loading ? (
        <>
          {skeleton ? (
            <Grid container direction="column" spacing={spacing}>
              <FeedSkeletons
                skeleton={skeleton}
                skeletonCount={skeletonCount}
              />
            </Grid>
          ) : (
            <Spinner size="small" />
          )}
        </>
      ) : (
        <>
          {error ? (
            <ErrorPlaceholder onAction={onRefresh} {...errorPlaceholderProps} />
          ) : (
            <>
              {empty ? (
                <EmptyPlaceholder
                  disabled={emptyDisabled}
                  title={emptyTitle}
                  subtitle={emptySubtitle}
                  {...emptyPlaceholderProps}
                />
              ) : (
                <>
                  {displayValue === "infinite" && (
                    <FeedInfiniteList
                      items={items}
                      hasMore={hasMore}
                      contentProps={contentProps}
                      spacing={spacing}
                      onLoadMore={handleLoadMore}
                    />
                  )}
                  {displayValue === "incremental" && (
                    <FeedIncrementalList
                      items={items}
                      spacing={spacing}
                      skeleton={skeleton}
                      skeletonCount={skeletonCount}
                      hasMore={hasMore}
                      loadingMore={loadingMore}
                      onLoadMore={handleLoadMore}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

Feed.propTypes = {
  display: PropTypes.oneOf([
    "infinite",
    "incremental",
    "default", // @deprecated, use "infinite"
  ]),
  items: PropTypes.any,
  spacing: PropTypes.number,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  hasMore: PropTypes.bool,
  skeleton: PropTypes.node,
  skeletonCount: PropTypes.number,
  contentProps: PropTypes.object,
  emptyPlaceholderProps: PropTypes.object,
  errorPlaceholderProps: PropTypes.object,
  onLoadMore: PropTypes.func,
  onRefresh: PropTypes.func,
  // @deprecated, use "emptyPlaceholderProps"
  emptyDisabled: PropTypes.bool,
  emptyTitle: PropTypes.string,
  emptySubtitle: PropTypes.string,
};

export default Feed;
